<template>
  <div>
    <vx-card :title="$t('todolist.todolist')" class="items-center">
      <!-- 待辦事項標題、交辦按鈕 -->
      <template slot="actions">
        <vs-button @click="showAddAssignment" class="cd-button-2w-icon" icon-pack="feather" icon="icon-plus">{{$t('todolist.ask')}}</vs-button>
      </template>
      <el-tabs v-model="activeName">
        <!-- 待辦事項 -->
        <el-tab-pane name="first">
          <span class="cd-p-normal" slot="label">
            <i class="el-icon-s-unfold"></i>
            {{$t('todolist.todolist')}}
            <el-badge v-show="todoutotal!=0" :value="todoutotal"></el-badge>
          </span>
          <vs-row>
            <!-- 待辦事項 List -->
            <vs-col :class="item.status==0 ? 'text-dark;' : 'cd-text-muted'" v-for="(item, index) in todolist" :key="index">
              <div class="items-center" :class="windowWidth<768?'':'flex justify-between'">
                <p>{{'From: '+item.dname+' : '+item.message}}</p>
                <div class="flex justify-end items-center">
                  <p style="white-space: nowrap;">{{item.created_at}}</p>
                  <el-select class="ml-4" :style="windowWidth<768?'width:100px;':'width:120px;'" v-model="item.status" :disabled="item.status==1" @change="sendStatus(item,item.did)">
                    <el-option :label="$t('todolist.process')" :value="0"></el-option>
                    <el-option :label="$t('todolist.finish')" :value="1"></el-option>
                  </el-select>
                </div>
              </div>
              <vs-divider />
            </vs-col>
            <!-- 沒資料 -->
            <vs-col class="mt-10" vs-type="flex" vs-justify="center" v-show="todolist==undefined || todolist==''">
              <p class="justify-content-between">{{$t('todolist.nodata')}}</p>
            </vs-col>
            <!-- 新增分頁 -->
            <vs-col vs-type="flex" vs-justify="center">
              <vs-pagination :max="windowWidth<768 ? 5 : 7" :total="Math.ceil(tometodolistTotal/10)" v-model="page" @change="getTodoList(page)"></vs-pagination>
            </vs-col>
          </vs-row>
        </el-tab-pane>
        <!-- 交辦事項 -->
        <el-tab-pane name="second">
          <span class="cd-p-normal" slot="label">
            <i class="el-icon-s-unfold"></i>
            {{$t('todolist.toAsk')}} {{"("+mytodoutotal+")"}}
          </span>
          <!-- 交辦事項 List -->
          <vs-col :class="item.status==0 ? 'text-dark;' : 'cd-text-muted'" v-for="(item, index) in mytodolist" :key="index">
            <div class="items-center" :class="windowWidth<768?'':'flex justify-between'">
              <p>{{'To: '+item.dname+' : '+item.message}}</p>
              <div class="flex items-center">
                <p style="white-space: nowrap;">{{item.created_at}}</p>
                <p class="mx-2" v-if="item.status==0">{{$t('todolist.process')}}</p>
                <p class="mx-4" v-else-if="item.status==1">{{$t('todolist.finish')}}</p>
              </div>
            </div>
            <vs-divider />
          </vs-col>
          <!-- 沒資料 -->
          <vs-col class="mt-10" vs-type="flex" vs-justify="center" v-show="mytodolist==undefined || mytodolist==''">
            <h3 class="justify-content-between">{{$t('todolist.nodata')}}</h3>
          </vs-col>
          <!-- 新增分頁 -->
          <vs-row>
            <vs-col vs-type="flex" vs-justify="center">
              <vs-pagination :max="windowWidth<768 ? 5 : 7" :total="Math.ceil(mytodolistTotal/10)" v-model="page" @change="getMyTodoList(page)"></vs-pagination>
            </vs-col>
          </vs-row>
        </el-tab-pane>
      </el-tabs>
    </vx-card>
    <!--交辦事項視窗-->
    <vs-prompt 
      :active.sync="addAssignment"
      :title="$t('todolist.ask')"
      @accept="addtodo"
      @close="close"
      :acceptText="$t('popup.add')"
      :cancelText="$t('popup.cancel')">
      <vs-select
        v-model="selectdid"
        style="margin-bottom: 10px;"
        :placeholder="$t('todolist.sendTo')" 
        @click.native="loadContact">
          <vs-select-item :key="index" :text="item.name" :value="item.did" v-for="(item, index) in contacts"/>
      </vs-select>
      <vs-textarea counter="200" style="background-color: aliceblue;" :counter-danger.sync="counterDanger" :placeholder="$t('todolist.content')" v-model="message"/>
    </vs-prompt >
  </div>
</template>

<script>
import { saveTodoList, updateTodoListStatus } from '@/api/user'

export default {
  computed: {
    todoutotal() {
      return this.$store.state.todoutotal
    },
    mytodoutotal() {
      return this.$store.getters.mytodoutotal
    },
    todolist() {
      return this.$store.getters.todolist
    },
    mytodolist() {
      return this.$store.getters.mytodolist
    },
    tometodolistTotal() {
      return this.$store.state.tometodolistTotal
    },
    mytodolistTotal() {
      return this.$store.state.mytodolistTotal
    },
    tometodolistPage() {
      return this.$store.state.tometodolistPage
    },
    mytodolistPage() {
      return this.$store.state.mytodolistPage
    },
    contacts() {
      return this.$store.getters.contacts
    },
    windowWidth() {
      return this.$store.state.windowWidth
    }
  },
  mounted() {
    let _self = this
    _self.getMyTodoList(_self.mytodolistPage)
    _self.getTodoList(_self.tometodolistPage)
  },
  data() {
    return {
      activeName: 'first',
      message: '',
      selectdid: '',
      counterDanger:false,
      page: 1,
      addAssignment:false,
    }
  },
  methods: {
    // 載入傳送對象
    loadContact() {
      this.$store.dispatch('fetchContact')
    },
    // 待辦事項 狀態改完成
    sendStatus(item,did) {
      let _self = this
      item.sdid = did
      updateTodoListStatus(item).then(res => {
        if(res.data.status=='OK'){
          _self.$store.commit('SET_TODO_UNFINISH_TOTAL',res.data.count)
          _self.notify('grey', _self.$t('message.update_success'), '')
          _self.getMyTodoList(_self.mytodolistPage)
        }
      })
    },
    // 關閉vs-prompt視窗
    close(){},
    // 取 待辦事項 List
    getTodoList(page) {
      var _self = this
      _self.$vs.loading()
      _self.$store.dispatch('fetchToMeTodoList', page).then(() => {
        window.scrollTo(0,0);
        _self.$vs.loading.close()
      }).catch((error) => { 
        console.log(error)
      })
    },
    //開啟交辦事項視窗
    showAddAssignment(){
      this.addAssignment=true
    },
    // 取 交辦事項 List
    getMyTodoList(page) {
      var _self = this
      _self.$vs.loading()
      _self.$store.dispatch('fetchMyTodoList', page).then(() => {
        window.scrollTo(0,0);
        _self.$vs.loading.close()
      }).catch((error) => { 
        console.log(error)
      })
    },
    // 新增 交辦事項
    addtodo() {
      let _self = this
      if(_self.selectdid=='' || _self.message==''){
        _self.notify('danger', _self.$t('message.send_error'), _self.$t('message.checkField'))
        return false
      }
      saveTodoList({'to_pid': _self.selectdid, 'message': _self.message}).then(res => {
        if(res.data.status == 'OK'){
          _self.notify('grey', _self.$t('message.send_success'), '')
          _self.message = ''
          _self.selectdid = ''
          _self.getTodoList(1)
          _self.getMyTodoList(1)
        }
      })
    },
    // 視窗通知
    notify(color, title, text){
      this.$vs.notify({
        color: color,
        title: title,
        text: text,
        position:'top-center'
      })
    },
  },
}
</script>
<style scope>
/* 手機版排版 */
@media screen and (max-width: 576px){
  .vs-list--item{
    display: inline-block;
  }
}
</style>
